.page-banner {
  position: relative;
  padding: 0;
  color: #ffffff;
  background: var(--theme-color-black);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.page-banner .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
}

/* .page-banner .image-layer {
  background-image: url("../../assets/img/about-us-page-banner.png");
} */

.page-banner .shape-1 {
  position: absolute;
  right: 0;
  top: 0;
  width: 250px;
  max-width: 100%;
  height: 100%;
  background: url("../../assets/img/right-curved-bg.png") right bottom no-repeat;
  z-index: 1;
}

.page-banner .shape-2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 250px;
  max-width: 100%;
  height: 100%;
  background: url("../../assets/img/right-white-curve.png") right top no-repeat;
  z-index: 1;
}

.page-banner .banner-inner {
  position: relative;
  display: block;
  text-align: center;
  z-index: 3;
  /* height: -100px; */
}

.page-banner .inner-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 4rem;
}

.page-banner h1 {
  position: relative;
  font-size: 50px;
  color: #ffffff;
  line-height: 0.9em;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  word-break: break-word;
}

.page-banner .page-nav {
  position: relative;
  padding-top: 5px;
  text-align: center;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.page-banner .bread-crumb {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-banner .bread-crumb li {
  position: relative;
  float: left;
  font-size: 14px;
  line-height: 30px;
  color: #ccd6df;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: default;
  padding-right: 15px;
  margin-right: 15px;
}

.page-banner .bread-crumb li:before {
  position: absolute;
  right: -15px;
  width: 30px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  content: "-";
}

.page-banner .bread-crumb li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.page-banner .bread-crumb li:last-child:before {
  display: none;
}

.page-banner .bread-crumb li a {
  color: #ffffff;
  font-weight: 400;
  transition: all 300ms ease;
}

.page-banner .bread-crumb li a:hover,
.page-banner .bread-crumb li.active {
  color: var(--theme-color-orange);
}

.page-banner .bread-crumb li:last-child {
  padding-right: 0;
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .page-banner h1 {
    font-size: 30px;
    line-height: normal;
  }

  .page-banner .bread-crumb li {
    font-size: 12px;
  }

  .page-banner .inner-container {
    padding-top: 2rem;
  }

  .page-banner {
    height: 250px;
  }
}