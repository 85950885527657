@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --theme-color-orange: #ff600c;
  --theme-color-dark: #333333;
  --linkedin-blue: #0072b1;
  --theme-color-white: #ffffff;
  --theme-highlight-text: #20395c;
  --theme-bg-light: #eeeeee;
  --theme-color-black: #000000;
  --theme-color-overlay: #0e0f10;
}

html,
body {
  /* font-family: "Poppins", sans-serif; */
  font-family: "Raleway", sans-serif;
  color: var(--theme-highlight-text);
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

p {
  font-size: 14px;
}

.text-justify {
  text-align: justify !important;
}

.mb-02 {
  margin-bottom: 0.2rem !important;
}

.carousel-card {
  height: 100%;
  background: var(--theme-color-white);
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--theme-bg-light);
}

carousel-card-img {
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
carousel-card-img img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
  transition: all 0.3s ease-out 0s;
  width: 100% !important;
}
.carousel-card:hover carousel-card-img img {
  transform: scale(1.1);
}
.carousel-card-content {
  padding: 1rem;
  /* position: relative;
      z-index: 1; */
  /* background: transparent; */
  /* height: 240px; */
}
/* .carousel-card-content::before{
      position: absolute;
      content: "";
      width: 100%;
      height: 0;
      background: var(--theme-bg-light);
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 0 0 5px 5px;
      transition: all 0.3s ease-out 0s;
  }
  .carousel-card:hover .carousel-card-content::before{
      height: 100%;
  } */

.carousel-card-content h3 {
  font-size: 1.35rem;
  font-weight: 600;
}
.carousel-card-content p {
  font-size: 0.9rem;
  font-weight: 500;
  color: #4f4f4f;
  text-align: justify;
}

.btn-theme {
  background-color: var(--theme-color-orange) !important;
  border-color: var(--theme-color-orange) !important;
}

.accordion-button:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.accordion-button:not(.collapsed) {
  color: var(--theme-color-dark) !important;
  background-color: var(--theme-bg-light) !important;
  border-color: var(--theme-bg-light) !important;
}

.accordion-header button {
  font-weight: bold;
}

.carousel-card-icon {
  width: 55px;
  height: 55px;
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .main-text,
  .section-highlighter {
    text-align: left !important;
  }
}

@media (max-width: 568px) {
  .carousel-card {
    margin-left: 0;
    margin-right: 0;
  }
  .technology-card {
    margin: 25px 45px 30px 25px;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(1% + 8px) !important;
  }
}
