.contact-us-inner {
    /* margin-top: -80px; */
    /* margin-bottom: 5rem; */
    background-color: #ffffff;
    padding: 1rem;
    transform: translateY(20px);
    border-radius: 10px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    border-top: 2px solid var(--theme-color-orange);
  }
  .contact-us-inner-text {
    width: 80%;
    padding: 1rem 1.5rem;
  }
  .contact-us-inner-btn {
    width: 20%;
  }
  .contact-us-inner-btn .btn {
    padding: 1rem 2rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 992px) {

    .contact-us-inner-text,
    .contact-us-inner-btn {
      width: 100%;
    }
    .contact-us-inner-btn a {
      width: 100%;
      display: block;
    }
  }