.contact-us-logo {
  width: 80px;
  height: 80px;
}

.logo {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.contact-us-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contact-us-icon svg {
  font-size: 26px;
  color: var(--theme-color-orange);
}

.contact-us-page a {
  color: var(--theme-color-dark);
  transition: all ease-in-out 0.3s;
}

.contact-us-page a:hover {
  color: var(--theme-color-orange);
}
@media (max-width: 992px) {
  .contact-us-inner {
    transform: translateY(40px);
  }
  .contact-us-inner-text,
  .contact-us-inner-btn {
    width: 100%;
  }
  .contact-us-inner-btn a {
    width: 100%;
    display: block;
  }
}
@media (max-width: 568px) {
  .contact-us-page a {
    font-size: 0.9rem;
  }
  /* .contact-form-frame {
    width: 100%;
    height: 540px;
  } */
}
