.read-more-button a {
    display: flex;
    width: fit-content;
}


.blog-content {
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    margin-top: 0;
    margin-bottom: 20px !important;
}

.blog-section .technology-card {
    padding: 0;
    overflow: hidden;
    margin-bottom: 10px;
}

.blog-section .technology-card .technology-card-inner {
    padding: 0;

}

.blog-section .technology-card .technology-card-inner .service-visual {
    margin-bottom: 10px;
}

.blog-content-card {
    padding: 15px;
    padding-top: 0;
}

.blog-section .technology-card {
    text-align: left !important;
}

.blog-content-card span,
.date-content {
    /* color: var(--theme-color-orange) !important; */
    font-weight: 500;
}

.blog-section .blog-content-card svg,
.blog-detail-main .blog-btn-card svg {
    width: 25px !important;
    color: var(--theme-color-orange) !important;
    margin-right: 5px;
}

.blog-detail-main h2 {
    text-align: center;
}

.blog-detail-main p {
    text-align: center;
}

.blog-detail-main .col-lg-8 img {
    width: 100%;
}

/*  */
.service-page .form-appoinment,
.blog-btn-card {
    border-radius: 10px;
    padding: 1rem;
}

.blog-btn-card ul {
    padding: 0;
}

.blog-btn-card ul li {
    list-style: none;
    align-items: flex-start;
}



.blog-btn {
    width: 100%;
    /* text-align: center; */
    padding: 10px 15px;
    display: inline-block;
    border-radius: 5px;
    /* border: 1px solid #000; */
    margin-bottom: 1rem;
    color: #000;
}

.service-btn.active {
    color: #fff;
    background-color: var(--theme-color-dark);
}

.date-content {
    color: var(--theme-color-orange) !important;
}

.blog-side-image {
    /* width: 150px; */
    width: 25%;
    aspect-ratio: 1/1;
}

.blog-side-image img {
    height: 100%;
}

.blog-side-card {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(20, 21, 21, 0.1);
}

.blog-side-card:last-child {

    border-bottom: none;
}

.blog-detail-main .blog-btn-card .blog-side-card .blog-side-content {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    width: 70%;
}

.blog-detail-main .blog-btn-card .blog-side-content a {
    font-size: 1.2rem;
    font-weight: bolder;
    color: var(--theme-color-dark);
}

.blog-detail-main .blog-btn-card .blog-side-image img {
    object-fit: cover;
    border-radius: 5px;
}

.blog-detail-main .blog-btn-card svg {
    width: 20px !important;
}

.heading-content {
    height: 100px !important;
}

.recent-blogs {
    padding-top: 0;
    transition: .5s;
}

.recent-blogs.active {
    padding-top: 150px;
}

.blog-page {

    margin: 0;
}

.blog-side-content span {
    font-weight: 500;
}

.blog-btn-card span {
    font-weight: 500;
}

.blog-detail-page-banner .page-banner h1 {
    font-size: 2.3rem !important;
}


.view a {
    padding: 1rem 2rem;
}


@media (max-width:568px) {

    .blog-detail-page-banner .page-banner {
        padding-top: 4rem;
        padding-bottom: 2rem;
        height: auto;
    }

    .blog-detail-page-banner .page-banner .bread-crumb li:before {
        line-height: 25px;
    }

    .blog-detail-page-banner .page-banner .bread-crumb li:last-child {
        line-height: 1.5;
    }

    .blog-detail-page-banner .page-banner .bread-crumb {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .blog-detail-page-banner .page-banner h1,
    .blog-detail-main .blog-btn-card .blog-side-content a {
        font-size: 0.9rem !important;
    }

    .recent-blogs {
        position: static !important;
    }

    .recent-blogs.active {
        padding-top: 0;
    }

    .blog-section .react-multi-carousel-list {
        padding: 0;
    }
}