.copyright {
  text-align: right;
  color: var(--theme-color-white);
  margin-right: 100px;
}

.design,
.design a {
  text-decoration: none;
  color: var(--theme-color-white);
}

footer {
  background: var(--theme-color-dark) url(../../assets/img/footer-bg.png) center top no-repeat;
}

.footer-logo {
  width: 60px;
  height: auto;
}

.footer-menu {
  display: flex;
  justify-content: end;
  align-items: center;
  list-style-type: none;
  gap: 40px;
  margin-bottom: 0;
  height: 50px;
}

.footer-menu li a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  transition: all ease-in-out 0.3s;
}

.footer-menu li a:hover {
  text-decoration: none;
  color: var(--theme-color-orange);
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

.shape-left {
  position: absolute;
  left: 0;
  z-index: -1;
}

.shape-right {
  position: absolute;
  right: 0;
  z-index: -1;
}

.shape-left .shape-img {
  width: 320px;
}

.shape-right .shape-img {
  width: 320px;
}

.back-to-top {
  background-color: var(--theme-color-orange);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 7px;
  position: fixed;
  bottom: 20px;
  right: 50px;
  cursor: pointer;
  z-index: 99;
  opacity: 0;
  transition: all ease-in-out 0.3s;
}

.back-to-top:hover {
  background-color: var(--theme-color-dark);
  box-shadow: 0px 12px 10px -8px rgb(0 0 0 / 50%);
  -webkit-box-shadow: 0px 12px 10px -8px rgb(0 0 0 / 50%);
}

.back-to-top svg {
  color: var(--theme-color-white);
  font-size: 24px;
}

.footer .mega-menu-list {
  padding-left: 0;
}

.footer .mega-menu-list li {
  list-style-type: none;
  margin-bottom: 15px;
  margin-top: 15px;
}

.footer .mega-menu-list li a {
  color: var(--theme-color-white);
  font-size: 15px;
}

.footer .mega-menu-list li a:hover {
  color: var(--theme-color-orange);
  transition: all ease-in-out .3s;
}

.footer .mega-menu-list li a:hover svg {
  transform: translateX(3px);
  transition: all ease .3s;
}

.footer .contact-info .address {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}

.footer .contact-info .address svg {
  color: var(--theme-color-orange);
  margin-top: 5px;
}

.footer .contact-info .address:nth-child(3) {
  color: var(--theme-color-white);
  margin-bottom: 1rem;
}

.footer .contact-info .address a {
  color: var(--theme-color-white);
}

.footer .global-strength-icon svg {
  font-size: 95px;
}

.footer .global-strength-title {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}

.footer p {
  margin-bottom: 0;
}

footer a {
  color: #fff;
}

/* footer {
  padding-top: 6rem;
} */

@media screen and (max-width: 1400px) and (min-width:1100px) {
  .footer .global-strength-icon svg {
    font-size: 75px;
  }

  .footer .global-strength-title {
    font-size: 25px;
  }
}

@media screen and (max-width: 768px) {
  footer {
    /* padding-top: 11rem; */
    padding: 50px 0;
  }

  .footer-menu {
    height: auto;
    padding: 0;
  }

  footer .design,
  footer .copyright {
    text-align: center;
    margin-right: auto;
  }

  .footer-menu li a {
    text-decoration: none;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    transition: all ease-in-out 0.3s;
    display: block;
    padding: 10px 20px;
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .back-to-top {
    bottom: 20px;
    right: 40px;
  }

}

@media (max-width:568px) {
  .footer .global-strength-icon {
    margin: 0 auto;
  }

  .footer .global-strength-icon svg {
    font-size: 3rem;
  }

  .footer .global-strength-title {
    font-size: 1.3rem;
  }

  .footer a {
    font-size: .9rem;
  }
}