.float-image-2 {
  height: 70vh;
  width: auto;
  background-image: url(../../../assets/img/software-development-page-image.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
}

.check-icon {
  font-size: 40px;
  color: var(--theme-color-orange);
}

@media screen and (max-width: 768px) {
  .float-image-2 {
    height: 50vh;
    width: auto;
    background-image: url(../../../assets/img/software-development-page-image.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: unset;
  }
}
