/* .float-image {
  position: absolute;
  left: 0;
  width: 390px;
}

@media screen and (max-width: 768px) {
  .float-image {
    position: relative;
    left: 0;
    width: 390px;
  }
} */

.float-image {
  height: 70vh;
  width: auto;
  background-image: url(../../../assets/img/software-development-page-image.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
}

@media screen and (max-width: 768px) {
  .float-image {
    height: 50vh;
    width: auto;
    background-image: url(../../../assets/img/software-development-page-image.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: unset;
  }
}
