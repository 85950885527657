.hiring-close {
    background: #6c757d4a !important;
    border: none !important;
    color: #fff !important;
    padding: 8px 10px;
    border-radius: 0.375rem !important;
}

.card-one {
    background: #fff;
    background: var(--theme-color-white);
    border: 1px solid #eee;
    border: 1px solid var(--theme-bg-light);
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .05);
    height: 100%;
    padding: 1rem;
}


.card-one img {
    height: 80px;
}

.card-one h3 {
    margin-bottom: 0;
}

.card-one .info {
    background: transparent;
    color: orangered;
}

.card-one .info:hover {
    background: #ff600c !important;
    color: #fff !important;
}