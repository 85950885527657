nav {
  transition: all ease 0.3s;
}

.logo {
  width: 100%;
  height: auto;
}

.custom-nav:hover .navbar {
  background-color: var(--theme-color-overlay);
}

.navbar-nav .nav-link {
  color: var(--theme-color-white);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  border-radius: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: pointer;
  margin-right: 5px;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
  color: var(--theme-color-white);
  background-color: var(--theme-color-orange);
  border-radius: 7px;
}

.bg-white.fixed-top .navbar-nav .nav-link {
  color: var(--theme-color-dark);
}

.bg-white.fixed-top .talk-to-us p {
  margin-bottom: 0;
  color: var(--theme-color-dark);
  text-transform: capitalize;
  text-decoration: none;
}

.bg-white.fixed-top .talk-to-us-icon {
  color: var(--theme-color-dark);
  font-size: 46px;
}

/* .nav-link svg {
  width: 28px;
  height: auto;
  border-radius: 10px;
} */

.bg-white.fixed-top .border-bottom {
  border-bottom: none !important;
}

.navbar-nav .nav-link.find-us {
  display: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--theme-color-dark);
  text-decoration: none;
  background-color: var(--theme-color-orange);
  border-radius: 0px;
}

.dropdown-item:focus {
  color: var(--theme-color-white);
}

.nav-link.dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: var(--theme-color-white);
}

.mega-menu {
  position: fixed;
  top: 111px;
  z-index: 9999;
  left: 0;
  right: 0;
  height: 430px;
  color: var(--theme-color-white);
  opacity: 1;
  background-color: #2e2e2e;
  box-shadow: 0px 12px 10px -8px rgb(0 0 0 / 50%);
  -webkit-box-shadow: 0px 12px 10px -8px rgb(0 0 0 / 50%);
  overflow: hidden;
}

.mega-menu .col {
  border-left: 2px solid rgb(72 72 72 / 50%);
}

.mega-menu .col:first-child,
.mega-menu .col:nth-child(2) {
  border-left: 0px solid var(--theme-color-white);
}

.dropdown-menu.show {
  display: block !important;
}

.logo-box {
  background-color: var(--theme-color-white);
  border-radius: 50%;
  display: block;
  padding: 12px;
  width: 90px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}

.talk-to-us p {
  margin-bottom: 0;
  color: var(--theme-color-white);
  text-transform: capitalize;
  text-decoration: none;
}

.mega-menu .mega-menu-list {
  padding-left: 0;
}

.mega-menu .mega-menu-list li {
  list-style-type: none;
  margin-bottom: 15px;
  margin-top: 15px;
}

.mega-menu .mega-menu-list li a {
  color: var(--theme-color-white);
  font-size: 15px;
}

.mega-menu .mega-menu-list li a:hover {
  color: var(--theme-color-orange);
  transition: all ease-in-out 0.3s;
}

.mega-menu .mega-menu-list li a:hover svg {
  transform: translateX(3px);
  transition: all ease 0.3s;
}

.talk-to-us-icon {
  color: var(--theme-color-white);
  font-size: 46px;
}

.navbar-img {
  width: auto;
  max-width: 350px;
  height: 100%;
  filter: grayscale();
  opacity: 0.5;
  object-fit: cover;
}

.nav-list {
  padding: 2rem 1.01rem;
}

.highlight {
  color: var(--theme-color-orange);
}

.talk {
  background-color: var(--theme-color-orange);
  border-color: var(--theme-color-orange);
}

.talk:hover,
.talk:active,
.talk:focus {
  background-color: var(--theme-color-overlay) !important;
  border-color: var(--theme-color-white) !important;
}

.website-link {
  color: var(--theme-color-white);
  text-align: center;
  transition: all ease 0.3s;
}

.website-link:hover {
  color: var(--theme-color-orange);
}

.prod-des {
  text-align: justify;
  font-size: 14px;
}

.prod-img {
  width: 75%;
  margin: 0 auto;
  display: block;
}

.navbar-toggler-icon {
  filter: invert(100%);
  width: 50px;
}

.mobile-menu .btn-close {
  filter: invert(100%);
  width: 35px;
  background-size: contain;
  opacity: 0.4;
}

.mobile-menu .offcanvas-header {
  height: 95px;
}

.close-arrow {
  display: none;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    gap: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .navbar-toggler:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    border: none;
  }

  .btn-close:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    border: none;
  }

  .navbar-nav .nav-link {
    font-size: 22px;
    padding: 10px;
    margin: 5px 0;
  }

  .dropdown-item {
    font-size: 22px;
    padding: 10px;
    margin: 5px 0;
    font-weight: 600;
  }

  .navbar-nav .dropdown-menu {
    border: 0;
    background: transparent;
  }

  .navbar-nav .nav-link:hover {
    border-radius: 10px;
  }

  .navbar-nav .nav-link.find-us {
    text-transform: none;
    display: block;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    border-radius: 10px;
  }

  .btn-talk-to-us {
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    width: 65%;
    margin: 40px auto;
    margin-top: 80px;
  }

  .mega-menu {
    top: 0;
    height: 100vh;
    overflow-y: auto;
  }

  .navbar-img {
    display: none;
  }

  .close-arrow {
    display: block;
    font-size: 1.8rem;
    line-height: 1;
    padding-left: 7px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .close-arrow svg {
    background-color: var(--theme-color-orange);
    padding: 5px;
    margin: 10px 0;
  }

  .offcanvas-header {
    height: 50px;
  }

  .nav-list.first {
    padding-top: 4rem;
  }
}