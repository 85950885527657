.technologies-logo {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
}
.technologies-logo img {
  width: 150px;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: normal;
  transition: all 1s;
}

.technologies-card {
  height: 100%;
  background: var(--theme-color-white);
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
  border-top: 5px solid var(--theme-color-orange);
  border-left: 1px solid #ff600c80;
  border-right: 1px solid #ff600c80;
  border-bottom: 1px solid #ff600c80;
}

.technologies-card:hover {
  box-shadow: 0px 0px 20px 10px rgba(255, 96, 12, 0.25);
  transform: translateY(-5px);
}

technologies-card-img {
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
technologies-card-img img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
  transition: all 0.3s ease-out 0s;
  width: 100% !important;
}
.technologies-card:hover technologies-card-img img {
  transform: scale(1.1);
}
.technologies-card-content {
  padding: 2rem;
}

.technologies-card-content h3 {
  font-size: 1.5rem;
  font-weight: 600;
}
.technologies-card-content p {
  font-size: 0.9rem;
  font-weight: 500;
  color: #4f4f4f;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .technologies-logo {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    align-items: center;
    justify-content: space-between;
  }
}
