.digital-marketing-services .technology-card {
    margin: 0;
    height: 100%;
}

.digital-marketing-services .technology-card .technology-card-inner {
    height: 100%;
}

.digital-marketing-services .col-lg-4 {
    margin-bottom: 1.5rem;
}

.digital-marketing-services .service-visual {
    filter: none;
}

.digital-marketing-services .how-it-works-card__title {
    font-size: 22px;
}

.form-digital-marketing {
    position: absolute;
    bottom: 40px;
    right: 60px;
    z-index: 1;
    background-color: #00000096;
    padding: 1rem;
    border-radius: 8px;
    color: #fff;
}

.form-digital-marketing .form-control {
    background: transparent;
    border-color: #ffffff46;
    color: #fff;
}

.form-digital-marketing .form-control::placeholder {
    /* padding: 10px !important; */
    /* height: auto !important; */
    /* font-size: .8rem !important; */
    /* line-height: 1 !important;
    padding-bottom: 0 !important; */
    color: #fff !important;
}

.form-control:focus {
    box-shadow: none;
}

.form-digital-marketing .form-floating>label {
    /* font-size: .8rem !important; */
    padding: 10px !important;

}

.form-digital-marketing .captcha-bg {
    padding: .375rem .75rem !important;
}

.digital-marketing-slider .slider-img {
    filter: none;
}

.digital-marketing-slider .carousel-caption {
    padding: 0;
}

.digital-marketing-slider .carousel-caption h1 {
    /* padding: 10px 0; */
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin-bottom: 0;
    line-height: 1;
}

.digital-marketing-slider .carousel-caption h1 span {
    line-height: 1.2 !important;
    margin-top: 5px;
    display: inline-block;
}

.digital-marketing-slider .carousel-caption {
    background-color: #00000096;
}

.digital-marketing-slider .carousel-caption {
    transform: translateY(-95%);
}

.digital-marketing-slider .carousel-item:before {
    background-color: #00000096;
}

.digital-marketing-slider .carousel-item.active .slider-img {
    transform: scale(1);
    object-position: bottom;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .digital-marketing-slider .carousel-caption {
        bottom: 0;
        top: inherit;
        transform: translateY(0) !important;
    }

    .carousel-caption h1 {
        border: none;
    }

    .website-image {
        object-fit: cover !important;
        object-position: left !important;
    }
}

@media (min-width: 360px) and (max-width: 740px) {
    .back-to-top {
        bottom: 20px;
        right: 28px;
    }
}