body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-title {
  margin-bottom: 0rem;
}

@media (max-width: 400px) {
  .main-title {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
}

/* Captcha */

.captcha-bg{
  background-color: #80808014 !important;
  border-radius: 0.375rem !important;
  user-select: none !important;
  border: 2px dotted #ced4da;
  padding: 14px 15px;
}
.iorotate {
  transition: 1s ease all;
  -moz-transition: 1s ease all;
  -webkit-transition: 1s ease all;
  transform: rotate(360deg) !important;
}
.aButton {
  transition: 1s ease all;
  -moz-transition: 1s ease all;
  -webkit-transition: 1s ease all;
}