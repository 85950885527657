@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  font-family: "Raleway", sans-serif;
  color: var(--theme-color-dark);
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

a {
  transition: all ease 0.3s;
}

a:hover {
  color: var(--theme-color-orange);
}

.slider-img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  transform: scale(1);
}

.carousel-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-color-overlay);
  opacity: 0.6;
  z-index: 1;
}

.carousel-item.active .slider-img {
  transform: scale(1.15);
  transition: all 7000ms linear;
}

.carousel-caption {
  z-index: 1;
  width: 50%;
  text-align: left;
  bottom: 0%;
  left: 3.5%;
  transform: translateY(-50%);
}

.carousel-caption h1 {
  border-left: 10px solid var(--theme-color-white);
  padding-left: 15px;
}

.carousel-caption h1 strong {
  color: var(--theme-color-orange);
}

.right-bottom-curve {
  position: absolute;
  right: 0;
  top: 0;
  width: 655px;
  max-width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  transform: translateX(150px);
  background: url(../../assets/img/right-curved-bg.png) right bottom no-repeat;
}

.right-top-curve {
  position: absolute;
  right: 0;
  top: 0;
  width: 164px;
  max-width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  transform: translateX(0px);
  background: url(../../assets/img/right-white-curve.png) right top no-repeat;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.social-media-block {
  display: flex;
  gap: 10px;
  position: fixed;
  bottom: 15px;
  left: 50px;
  z-index: 2;
  transition: all ease-in-out 0.3s;
}

.social-media-block a {
  display: flex;
  width: 35px;
  height: 35px;
  background-color: var(--theme-color-dark);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 0.3s;
}

.social-media-block a:hover {
  background-color: var(--theme-color-orange);
}

.social-media-block .social-media-icon {
  color: var(--theme-color-white);
  font-size: 24px;
}

.social-media-block .social-media-icon.facebook {
  font-size: 20px;
}

.whatsapp-icon {
  position: fixed;
  bottom: 5%;
  right: 3%;
  z-index: 1;
}

.whatsapp-icon img {
  width: 50px;
}

.service-wrapper {
  margin-bottom: 12px;
  margin-top: 12px;
  position: relative;
}

.services {
  background-color: var(--theme-color-black);
  color: var(--theme-color-white);
  transition: all ease-in-out 0.3s;
}

.services:hover,
.react-multi-carousel-item--active .services {
  background-color: var(--theme-color-orange);
  color: var(--theme-color-black);
}

.services .card-title {
  margin-bottom: 0;
  padding: 30px 30px;
  min-height: 120px;
  line-height: normal;
}

.services .card-title.count {
  margin-bottom: 0;
  padding: 15px 30px;
  min-height: auto;
  line-height: normal;
  font-size: 32px;
  color: var(--theme-color-dark);
}

.services {
  min-height: 180px;
  overflow: hidden;
}

.services .bottom-curve {
  position: absolute;
  right: -70px;
  bottom: 0;
  width: 100px;
  height: 80%;
  background: var(--theme-color-black);
  opacity: 0.2;
  z-index: 0;
  transform: skewX(0deg) scaleX(0);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.services:hover .bottom-curve {
  transform: skewX(-35deg) scaleX(1);
}

.service-wrapper .services-icon-lg {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
}

.services:hover .services-icon-lg {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

.floating-enquiry {
  position: fixed;
  top: 50%;
  z-index: 2;
  background-color: var(--theme-color-orange);
  border-radius: 7px 0px 0 7px;
  width: 50px;
  height: 50px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.floating-enquiry svg {
  font-size: 30px;
  color: var(--theme-color-white);
}

#enquiryForm {
  height: 90vh;
  top: 50%;
  transform: translateY(-50%) !important;
  border-radius: 7px 0 0 7px;
}

.offcanvas-backdrop {
  height: 130vh;
  overflow: scroll;
}

.py-lg-space {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.about-section .image-column {
  position: relative;
  margin-bottom: 30px;
}

.about-section .image-column .image-block:nth-child(1) {
  margin-left: 180px;
}

.about-section .image-column .image-block:nth-child(2) {
  margin-right: 140px;
  margin-top: -170px;
}

.about-section .image-column .image-block {
  position: relative;
  display: block;
  border-radius: 7px;
  overflow: hidden;
}

.about-section .image-column .image-block img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  border-radius: 7px;
}

.about-section .image-column .image-block .image-2 {
  width: 350px;
  height: auto;
}

.about-section .image-column .inner:before {
  content: "";
  position: absolute;
  left: 0;
  width: 15px;
  height: 280px;
  background: var(--theme-color-orange);
  border-radius: 8px;
}

/* .about-section .text-column .text {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
} */

.about-section .text-column .text {
  position: relative;
  font-size: 18px;
  text-align: justify;
}

.about-section .text-column .text .since {
  position: relative;
  float: left;
  margin-left: 82px;
  margin-top: 10px;
}

.about-section .text-column .text .since:before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0px;
  width: 80px;
  height: 80px;
  background: #e8e9e9;
  border-radius: 50%;
}

.about-section .text-column .text .since .txt {
  position: relative;
  display: block;
  padding: 21px 5px;
  width: 100px;
  height: 100px;
  line-height: 24px;
  text-align: center;
  background: var(--theme-color-black);
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 50%;
  font-size: 24px;
  line-height: 1;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 27px;
}

.about-section .text-column .text .since:after {
  content: "";
  position: absolute;
  left: -42px;
  top: 0px;
  bottom: 0px;
  border-left: 2px solid var(--theme-color-orange);
}

.list-check {
  color: var(--theme-color-orange);
}

.main-text {
  font-size: 1rem;
  text-align: justify;
}

.main-text.highlight-text {
  color: var(--theme-color-orange);
  font-size: 24px;
  text-align: unset;
}

.about-list li {
  font-size: 18px;
}

.technology-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 2px;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
  transition: background 500ms ease;
  margin: 25px 25px 30px 25px;
}

/* .technology-card:hover {
    background-color: var(--theme-color-orange);
} */

.technology-card-inner {
  border-radius: 8px;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.how-it-works-card__icon {
  font-size: 75px;
  color: var(--theme-color-orange);
  display: block;
  line-height: 1;
  margin: auto;
  margin-bottom: 10px;
}

.how-it-works-card__title a {
  margin: 0;
  color: var(--theme-color-black);
  line-height: 1;
  text-transform: capitalize;
  font-size: 22px;
}

.why-us {
  padding: 5% 0;
  background-image: url(../../assets/img/why-us-parallax.png);
  background-size: cover;
  background-attachment: fixed;
  filter: grayscale();
  position: relative;
}

.why-us::before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}

.how-it-works-card__text {
  margin: 0;
  font-size: 1rem;
  line-height: 24px;
  color: var(--thm-text);
  font-family: var(--thm-b-font);
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 0px;
  text-align: justify;
}

.how-it-works-card__link {
  text-align: center;
  color: var(--theme-color-black);
  font-size: 12px;
  letter-spacing: 0.1em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  transition: color 300ms ease;
}

.react-multi-carousel-dot button {
  width: 30px;
  height: 8px;
  border-radius: 0;
  background-color: #ddd;
}

/* .how-it-works-card__link:hover {    
    color: var(--theme-color-orange);
} */

.react-multi-carousel-dot button {
  width: 30px !important;
  border-radius: 0 !important;
  height: 5px !important;
  border: none !important;
}

.react-multi-carousel-dot--active button {
  background: var(--theme-color-dark) !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(1% + -12px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(2% + 1px) !important;
}

.bg-bechofy {
  background-color: #fffc00;
  height: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.bg-kazamatrix {
  background-color: var(--theme-color-black);
  height: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.bg-grubdigest {
  background-color: var(--theme-bg-light);
  height: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.bg-grubdigest img,
.bg-kazamatrix img,
.bg-bechofy img {
  width: 300px;
}

.global-products .section-highlighter {
  font-weight: 700;
}

.global-products .global-product-title {
  font-weight: 700;
  margin-bottom: 10px;
}

.web-and-mobile .section-highlighter {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 1.9rem;
}

.web-and-mobile .why-choose-text {
  text-align: justify;
  min-height: 120px;
}

.service-visual {
  width: 100%;
  margin-bottom: 20px;
  filter: grayscale();
}

.service-read-more {
  background-color: var(--theme-color-orange);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 7px;
  position: relative;
  cursor: pointer;
  z-index: 99;
  opacity: 1;
  transition: all ease-in-out 0.3s;
  margin-left: auto;
}

.service-read-more svg {
  color: var(--theme-color-white);
  font-size: 24px;
}

.ecommerce-service {
  padding: 5% 0 0 0;
  background-image: url(../../assets/img/information-parallax.png);
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.ecommerce-service::before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}

.ecommerce-service .header-inner {
  position: relative;
  padding: 50px 50px 20px;
  min-height: 260px;
  background: var(--theme-color-orange);
  font-weight: 800;
  font-size: 52px;
  line-height: 1.2em;
  letter-spacing: 0px;
  color: var(--theme-color-black);
  border-radius: 25px 25px 0px 0px;
}

.eCommerce-tab {
  position: relative;
}

.eCommerce-tab .nav-link .count {
  position: absolute;
  left: 10px;
  top: 30px;
  width: 60px;
  height: 60px;
  text-align: center;
  background-color: var(--theme-color-orange);
  padding: 12px 10px;
  line-height: 35px;
  font-size: 30px;
  letter-spacing: 1px;
  color: var(--theme-color-white);
  text-transform: uppercase;
  border-radius: 50%;
  transition: all 500ms ease;
}

.eCommerce-tab.nav-item .nav-link {
  padding: 3rem 6rem !important;
  background-color: #ffffff;
  color: #000000 !important;
  /* border-bottom: 1px solid var(--theme-color-dark); */
  border-radius: 0;
}

/* .eCommerce-tab.nav-item:last-child .nav-link {    
    border-bottom: 0px solid var(--theme-color-dark);
} */
.eCommerce-tab.nav-item .nav-link.active .count {
  background-color: var(--theme-color-dark);
}

.product-panel-left {
  background-color: var(--theme-bg-light);
  padding: 10px;
  border-radius: 10px;
}

.product-panel-left .nav-link,
.product-panel-left .accordion-item,
.product-panel-left .accordion-button {
  background-color: transparent;
  color: var(--theme-color-dark) !important;
  border: none;
}

.product-panel-left .accordion-button {
  font-weight: 500;
  padding: 0.7rem 1rem !important;
}

.product-panel-left .accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}

.product-panel-left .nav-link.active,
.product-panel-left .accordion-button:not(.collapsed) {
  color: var(--theme-color-white) !important;
  background-color: var(--theme-color-dark) !important;
}

.product-panel-left .accordion-button:not(.collapsed) {
  border-radius: 6px;
}

.left-curve {
  position: absolute;
  bottom: -48px;
  left: 12px;
  background-image: url(../../assets/img/curve-down-left.png);
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
}

.why-us .main-text {
  font-size: 23px;
}

.e-cart-icon {
  position: absolute;
  font-size: 30rem;
  top: 25%;
  right: 20px;
  opacity: 0.05;
}

.header-inner-div {
  border-radius: 10px 10px 0 0;
}

.ecommerce-service .title-box {
  border-radius: 25px 25px 0 0;
}

.ecommerce-service .title-box .service-read-more {
  margin-bottom: 1px;
}

.talk.mt-5px {
  margin-top: 5px;
}

.react-multiple-carousel__arrow {
  min-width: 30px !important;
  min-height: 30px !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 1rem !important;
}

@media screen and (max-width: 768px) {

  .carousel-fade .carousel-item,
  .slider-img {
    height: 50vh !important;
    /* width: auto !important; */
  }

  .carousel-caption {
    top: 62%;
    transform: translateY(-62%) !important;
  }

  .whatsapp-icon img {
    width: 40px;
  }

  .right-bottom-curve {
    top: 110px;
    transform: translateX(0);
  }

  .ecommerce-service .header-inner {
    font-size: 36px;
  }

  .eCommerce-tab.nav-item .nav-link {
    padding: 3rem 0rem 3rem 5rem !important;
  }

  .social-media-block {
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
  }

  .ecommerce-service .header-inner {
    min-height: 200px;
    margin-bottom: -20px;
  }

  .carousel {
    position: relative;
    z-index: 999;
  }

  .carousel-caption {
    z-index: 1;
    width: 100%;
    text-align: left;
    bottom: 0%;
    left: auto;
    transform: translateY(-50%);
    padding: 10px;
    right: auto;
  }

  .about-section .image-column .inner:before {
    content: "";
    position: absolute;
    left: 10px;
    width: 15px;
    height: 280px;
    background: var(--theme-color-orange);
    border-radius: 8px;
  }

  .about-section .image-column .image-block:nth-child(2) {
    margin-left: 25px;
  }

  .carousel-caption h1 {
    font-size: 1.5rem;
    border-width: 5px;
    padding-right: 3.5rem;
  }

  .talk-to-us {
    font-size: 1rem;
  }

  .talk-to-us-icon {
    color: var(--theme-color-white);
    font-size: 55px;
  }

  .about-section .text-column .text .since {
    margin-left: auto;
    /* margin-top: 1.5rem;
    margin-right: 20px; */
    margin: 0;
    margin-right: 20px;
    margin-left: 2rem;
    display: flex;
    align-items: center;
  }

  .about-section .text-column .text .since:after {
    left: inherit;
    right: -20px;
    border-width: 5px;
    top: 48%;
    transform: translateY(-48%);
    height: 120px;
  }

  .about-section .text-column .text .since:before {
    top: 40%;
    transform: translateY(-40%);
  }

  .py-space {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

@media (max-width: 568px) {
  .information-technology-section .react-multi-carousel-list {
    padding: 0;
  }

  .navbar-brand a {
    width: 60px;
    height: 60px;
  }

  .navbar-toggler-icon {
    width: 40px;
  }

  .about-section .image-column .inner:before {
    width: 5px;
    height: 200px;
  }

  .about-section .image-column .image-block .image-2 {
    width: 100%;
  }

  .about-section .image-column .image-block:nth-child(2) {
    margin-top: -200px;
  }

  .about-section .image-column {
    margin-bottom: 3.5rem;
  }

  .main-text {
    font-size: 1.1rem !important;
    text-align: left;
  }

  .since-p {
    /* text-align: left; */
    font-size: 1.1rem;
  }
}

@media (max-width: 440px) {
  .about-section .image-column .inner:before {
    width: 5px;
    height: 250px;
  }

  .about-section .image-column .image-block:nth-child(2) {
    margin-top: -205px;
  }

  .about-section .image-column {
    margin-bottom: 4rem;
  }
}

@media (max-width: 400px) {
  .about-section .image-column .inner:before {
    width: 5px;
    height: 200px;
  }

  .about-section .image-column .image-block:nth-child(2) {
    margin-top: -175px;
  }

  .about-section .image-column {
    margin-bottom: 3.5rem;
  }
}

@media (max-width: 380px) {
  .about-section .image-column .inner:before {
    width: 5px;
    height: 190px;
  }

  .about-section .image-column .image-block:nth-child(2) {
    margin-top: -160px;
  }

  .about-section .image-column {
    margin-bottom: 3.4rem;
  }
}

@media (max-width: 1024px) {
  .carousel-item .slider-img {
    height: 100vh;
    width: auto !important;
  }

  /* .carousel-item.active .slider-img {
    transform: scale(1);
  } */
  .prod-img {
    width: 25%;
    margin: inherit;
    display: block;
  }

  .col {
    flex: unset;
  }
}